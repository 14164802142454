import { render, staticRenderFns } from "./wxLogin.vue?vue&type=template&id=799be694&scoped=true&"
import script from "./wxLogin.vue?vue&type=script&lang=js&"
export * from "./wxLogin.vue?vue&type=script&lang=js&"
import style0 from "./wxLogin.vue?vue&type=style&index=0&id=799be694&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799be694",
  null
  
)

export default component.exports